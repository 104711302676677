@import 'styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
  box-sizing: border-box;
  animation: showAuth 0.25s;

  @keyframes showAuth {
    from {
      opacity: 0;
    }
  }

  @media ($viewport-7) {
    width: 420px;
    margin: 25vh auto 0;
    border: 1px solid var(--secondary-color);
    border-radius: 4px;
  }
}

.title {
  margin-top: 60px;
  font-weight: normal;
  color: var(--text-color);

  @media ($viewport-7) {
    margin-top: 20px;
  }
}

.googleSignIn {
  width: 200px;
  margin-top: 25px;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
}

.delimiter {
  display: flex;
  align-items: center;
  margin-top: 20px;
  opacity: 0.5;
}

.divider {
  width: 100px;
  height: 1px;
  background-color: var(--text-color);
}

.or {
  margin: 0 18px;
}

.inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.input {
  margin: 2.5px 0;
  padding: 5px;
  border: 1px solid var(--text-color);
  border-radius: 4px;

  &:focus {
    outline: 1px solid dodgerblue;
  }
}

.error {
  height: 20px;
  margin: 0;
  color: red;
  font-size: 14px;

  &::first-letter {
    text-transform: capitalize;
  }
}

.resetPassword {
  align-self: end;
  color: #0095f6;
  cursor: pointer;
}

.submit {
  margin: 20px 0;
  padding: 7.5px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  background-color: #0095f6;
  color: white;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  @media ($not-mobile) {
    &:hover {
      opacity: 0.5;
    }
  }
}
