@import 'styles/variables';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  margin: 10px 0;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  background-color: var(--primary-color);

  @media ($viewport-4) {
    width: 400px;
    margin: 20px;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.name {
  flex-grow: 1;
  margin: 0 10px;
  border: none;
  outline: none;
  font-family: inherit;
  background-color: inherit;
  color: inherit;
  font-size: 18px;

  &::placeholder {
    color: var(--placeholder-color);
  }
}

.infoWrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 30%;
  height: 100%;
  font-size: 12px;

  @media ($viewport-4) {
    font-size: 16px;
  }
}

.extension {
  margin-right: 15px;
  font-size: 12px;

  @media ($viewport-4) {
    font-size: 16px;
  }
}

.upload {
  height: 100%;
  padding: 0 10px;
  border: none;
  border-radius: 0 4px 4px 0;
  outline: none;
  font-family: inherit;
  color: inherit;
  background-color: var(--secondary-color);
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
}

.uploadLabel {
  height: 15px;
}

.uploadIcon {
  width: 15px;
  margin-right: 10px;
  cursor: pointer;

  @media ($not-mobile) {
    &:hover {
      opacity: 0.5;
    }
  }
}

.downloadIcon {
  width: 15px;
  margin-right: 10px;
  text-align: center;
  cursor: pointer;

  @media ($not-mobile) {
    &:hover {
      opacity: 0.5;
    }
  }

  @keyframes loading {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.25;
    }
  }
}

.downloading {
  cursor: default;
  pointer-events: none;
  animation: loading 0.75s infinite alternate;
}

.deleteIcon {
  width: 14px;
  margin-right: 15px;
  cursor: pointer;
  animation: showDelete 0.25s;

  @media ($not-mobile) {
    &:hover {
      opacity: 0.5;
    }
  }

  @keyframes showDelete {
    from {
      opacity: 0;
    }
  }
}
