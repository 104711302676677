@import 'styles/variables';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  background-color: var(--primary-color);

  @media ($viewport-4) {
    width: 400px;
    margin: 20px;
  }
}

.pinIcon {
  z-index: 1;
  position: absolute;
  top: 2.5px;
  right: 3px;
  width: 14px;
  height: 14px;
  color: var(--placeholder-color);
  cursor: pointer;
}

.pinned {
  color: inherit;
}

.category,
.title {
  box-sizing: border-box;
  border: none;
  outline: none;
  font-family: inherit;
  color: inherit;
  background-color: inherit;
  text-align: center;

  &::placeholder {
    color: var(--placeholder-color);
  }
}

.category {
  padding: 4px;
  border-bottom: 1px solid var(--secondary-color);
  border-radius: 4px 4px 0 0;
  background-color: var(--secondary-color);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.title {
  margin: 8px 24px 0;
  padding: 0;
  resize: none;
  overflow: hidden;
  font-size: 20px;
  font-weight: bold;
}

.confirmMessage {
  margin-bottom: 5px;
  text-align: center;
  font-size: 14px;
  color: inherit;
}

.date {
  margin: 2.5px 0;
  text-align: center;
  font-size: 14px;
  color: rgb(160, 160, 160);
}

.save {
  z-index: 1;
  margin: 0 auto;
  padding: 3.75px 20px;
  border: none;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  font-family: inherit;
  text-align: center;
  color: inherit;
  background-color: var(--secondary-color);
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;

  @media ($not-mobile) {
    :hover {
      opacity: 0.75;
    }
  }
}
