@import 'styles/variables';

.wrapper {
  width: 95%;
  margin: 15px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media ($viewport-4) {
    margin-top: 30px;
  }
}
