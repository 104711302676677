:root {
  --text-color: #24292e;
  --bg-color: #fff;
  --primary-color: #fff;
  --secondary-color: #e1e4e8;
  --placeholder-color: #b4b4b4;
}

[data-theme='dark'] {
  --text-color: #fff;
  --bg-color: #000;
  --primary-color: #141414;
  --secondary-color: #1f1f1f;
  --placeholder-color: #787878;
}

* {
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji,
    Segoe UI Emoji;
  color: var(--text-color);
  background-color: var(--bg-color);
}

img {
  user-select: none;
}

svg {
  fill: currentColor;
  stroke: currentColor;
}

a:visited {
  color: unset;
}

select {
  appearance: none;
  padding: 0 30px 0 7.5px;
}

:global {
  .invisible {
    visibility: hidden;
  }

  .d-none {
    display: none;
  }

  .d-flex {
    display: flex;
  }

  .align-items-center {
    align-items: center;
  }

  .flex-1 {
    flex: 1;
  }

  .text-align-center {
    text-align: center;
  }

  .pointer-events-none {
    pointer-events: none;
  }

  .red {
    color: red;
  }
}
