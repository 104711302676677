@import 'styles/variables';

.wrapper {
  width: 80vw;
  max-width: 400px;
  margin: 25px auto 0;
  height: 32px;
  display: flex;
  justify-content: center;

  @media ($viewport-4) {
    margin-top: 50px;
  }
}

.category {
  width: 75px;
  border-radius: 5px 0 0 5px;
  outline: none;
  cursor: pointer;
  border: 1px solid var(--secondary-color);
  letter-spacing: 1px;
  font-size: 14px;
  color: inherit;
  background-color: var(--primary-color);

  @media ($viewport-4) {
    width: 90px;
  }
}

.search {
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  border: 1px solid var(--secondary-color);
  border-radius: 0 5px 5px 0;
  background-color: var(--primary-color);
}

.searchBox {
  width: 100%;
  height: 50%;
  padding-left: 30px;
  border: none;
  color: inherit;
  outline: none;
  background-color: transparent;

  &::placeholder {
    color: var(--placeholder-color);
  }
}

.magnifyingGlassIcon {
  position: absolute;
  left: 7.5px;
  width: 15px;
}
