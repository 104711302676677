@import 'styles/variables';

.wrapper {
  display: flex;
  position: relative;
  padding: 10px 15px;
  width: 100%;
  border-bottom: 2.5px solid var(--secondary-color);
  box-sizing: border-box;
  align-items: center;

  @media ($viewport-4) {
    padding: 12.5px 30px;
  }
}

.navLink {
  padding: 0 6px;
  box-sizing: border-box;
  color: var(--text-color);
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  opacity: 0.5;

  @media ($not-mobile) {
    &:hover {
      opacity: 1;
    }
  }
}

.active {
  opacity: 1;
  pointer-events: none;
}

.logo {
  width: 34px;
  margin-right: 10px;
}

.user {
  display: flex;
  margin-left: 10px;
  padding: 0;
  font-family: inherit;
  color: inherit;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  user-select: none;

  @media ($not-mobile) {
    &:hover {
      opacity: 0.5;
    }
  }
}

.userIcon {
  align-self: center;
  width: 20px;
}
